import styled from "@emotion/styled";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { removeFilter, setChildFilters, removeParentFilter } from "../../features/filtersSlice";
import DeleteButton from "../Buttons/DeleteButton";
import PlusButton from "../Buttons/PlusButton";
import DeleteDialog from "../Dialogs/DeleteDialog";
import FilterForm from "../Forms/FilterForm";
import { SortableWrapper } from "../Sortables/SortableItem";
import SortableList from "../Sortables/SortableList";
import FilterDialog from "../Dialogs/FilterDialog";
import { Anchor, Tooltip } from '@dnb/eufemia';
import { useTranslation } from "react-i18next";

export default function FilterParameter({ parameter, indexNo }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();


  const items = parameter.child_parameters ?? [];
  const [initialLength, setInitialLength] = useState(items.length);
  const [addContainer, setAddContainer] = useState();

  const [activeItemId, setActiveItemId] = useState();
  const [isDeleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [isEditDialogOpen, setEditDialogOpen] = useState(false);

  const [isDeleteParentDialogOpen, setDeleteParentDialogOpen] = useState(false);

  const [itemToDelete, setItemToDelete] = useState();

  var filter = "fromChild";
  // Add the component to the view, when the user clicks Save, it set the container to nothing: onClick={() => { setAddContainer() }}
  function handleAdd() {
    setActiveItemId();
    setAddContainer(
      <FilterForm
        parentId={parameter.id}
        id={initialLength + 1}
        initialData={{ name: "", tag: "" }}
        filter={filter}
        indexNo={indexNo}
        onClick={() => {
          setAddContainer();
        }}
      />,
    );
    setInitialLength(initialLength + 1);
  }
  function editParentFilter() {
    setEditDialogOpen(true)

  }
  function handleEdit(item) {
    setActiveItemId(item.id);
    setAddContainer(
      <FilterForm
        parentId={parameter.id}
        id={item.id}
        initialData={{ name: item.key, tag: item.value }}
        filter={filter}
        indexNo={indexNo}
        onClick={() => {
          setActiveItemId();
          setAddContainer();
        }}
      />,
    );
  }

  function setItems(updatedItems) {
    dispatch(setChildFilters({ id: parameter.id, children: updatedItems }));
  }

  function handleRemove() {
    const index = items.findIndex(({ id }) => id === itemToDelete);
    dispatch(removeFilter({ parentId: indexNo + 1, index: index }));
    if (itemToDelete === activeItemId) {
      setAddContainer();
    }
  }
  function handleRemoveParent() {
    dispatch(removeParentFilter({ parentId: parameter.id, index: indexNo }));

  }

  const handleDelete = (item) => {
    setItemToDelete(item.id)
    setDeleteDialogOpen(true)
  }
  const handleParentDelete = () => {
    setDeleteParentDialogOpen(true)
  }
  const renderListItem = (item) => (
    <SortableList.Item id={item.id}>
      <SortableWrapper selected={activeItemId === item.id} onClick={() => {
        handleEdit(item);
      }}>
    
        <StyledButton
          disabled={item.id === activeItemId}
        >
          <Title>{item.key}</Title>
          {item.value}
        </StyledButton>
      
        <DeleteButton handleDelete={() => { handleDelete(item) }} border={true} />
      </SortableWrapper>
    </SortableList.Item>
  );

  return (
    <Main>
      <ParentWrapper>
        <Startspace></Startspace>
        <ColumnTitle onClick={() => {
          editParentFilter();
        }}>{parameter.key}</ColumnTitle>
        <DeleteButton handleDelete={() => { handleParentDelete() }} border={true} />
      </ParentWrapper>
      <ColumnWrapper key={parameter.key}>

        <ChildWrapper>
          <SortableList
            items={items}
            onChange={setItems}
            renderItem={renderListItem}
          />
        </ChildWrapper>
        {addContainer}

        {!addContainer && <LinkWrapper tooltip={<Tooltip fixed_position>{t("filterPage.childTooltip")}</Tooltip>}>
          <PlusButton handleAdd={handleAdd}>add</PlusButton>
        </LinkWrapper>}
        {isDeleteDialogOpen ? <DeleteDialog handleRemove={handleRemove} type={"child"} closeDialog={() => setDeleteDialogOpen(false)} /> : null}
        {isDeleteParentDialogOpen ? <DeleteDialog handleRemove={handleRemoveParent} type={"parent"} closeDialog={() => setDeleteParentDialogOpen(false)} /> : null}
        {isEditDialogOpen ? <FilterDialog key={parameter.id} parameter={parameter} indexNo={indexNo}
          initialData={{ name: parameter.key, tag: parameter.value }}
          closeDialog={() => setEditDialogOpen(false)} /> : null}

      </ColumnWrapper>
    </Main>
  );
}
const Main = styled.div`
display: flex;
flex-direction: column;
align-items: center;
`
const LinkWrapper = styled(Anchor)`
&.dnb-anchor:hover {
   box-shadow:none;
   color: none ;
}
&.dnb-anchor .dnb-icon {
  display: inline-block;
  transform: translateY(0em);
}
`;
const ColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 20rem;
  min-height:20rem;
  text-align: left;
   border: 1px solid #333333;
   border-radius:8px;
   padding-bottom:20px;
   margin-top:10px;
   flex-basis: 100%;
  
 
`;
const Startspace = styled.div`
visibility: hidden;
`
const ColumnTitle = styled.div`
  font-weight: var(--font-weight-medium);
  font-size: var(--font-size-large);
  cursor: pointer;  
  text-align: center;
  &:hover {
   color: #14555A;
  }
  margin-left: 1rem;
`;

const Title = styled.div`
  font-weight: 500;
  text-align: left;
`;

const StyledButton = styled.button`
  display: flex;
  flex-direction: column;
  align-items: start;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
`;

// const Div = styled.div`
//   width: 100%;
// `;
const ParentWrapper = styled.div`
display: flex;
align-items: center;
flex-direction:row;
// margin-top:-2rem;
min-width:18rem;
 justify-content:space-between;
 padding-bottom:7px;
`;
const ChildWrapper = styled.div`
align-items: center;
padding: 5px;
padding-bottom:20px;
`
