import { createSlice } from "@reduxjs/toolkit";

export const filtersSlice = createSlice({
  name: "filters",
  initialState: {
    filters: {
      parameters: [],
    },
  },
  reducers: {
    setFilters: (state, action) => {
      state.filters.parameters = action.payload;
    },
    setChildFilters: (state, action) => {
      state.filters.parameters[action.payload.id - 1].child_parameters =
        action.payload.children;
    },
    addFilter: (state, action) => {
      if (!state.filters.parameters[action.payload.id - 1].child_parameters) {
        state.filters.parameters[action.payload.id - 1].child_parameters = [];
      }

      state.filters.parameters[action.payload.id - 1].child_parameters.push(
        action.payload.child,
      );
    },
    addParentFilter: (state, action) => {
      if (!state.filters.parameters) {
        state.filters.parameters= [];
      }

      state.filters.parameters.push(
        action.payload,
      );
      // console.log("state.filters.parameters....",state.filters.parameters)
    },


    removeFilter: (state, action) => {
      state.filters.parameters[
        action.payload.parentId - 1
      ].child_parameters.splice(action.payload.index, 1);
    },
    removeParentFilter: (state, action) => {
      state.filters.parameters.splice(action.payload.index, 1);
    },
    editFilter: (state, action) => {
      const index = state.filters.parameters[
        action.payload.id - 1
      ].child_parameters.findIndex(
        (filter) => filter.id === action.payload.child.id,
      );
      if (index !== -1) {
        state.filters.parameters[action.payload.id - 1].child_parameters[
          index
        ] = action.payload.child;
      }
    },
    editParentFilter:(state, action) => {
      state.filters.parameters[action.payload.id].key = action.payload.key;
      state.filters.parameters[action.payload.id].value = action.payload.value;
    },
  },
});

export const {
  setFilters,
  setChildFilters,
  addFilter,
  removeFilter,
  removeParentFilter,
  editFilter,
  addParentFilter,
  editParentFilter
} = filtersSlice.actions;
export default filtersSlice.reducer;
