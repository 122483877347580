/* eslint-disable */
import React, { useEffect, useState } from "react";
import api from "../apiConfig";
import styled from "@emotion/styled/macro";
import { InputWrapper, Label, StyledInput } from "../components/Forms/Form";
import { t } from "i18next";
import Button from "../components/Buttons/Button";
import Loader from "../components/Loaders/Loader";
import { useDispatch } from "react-redux";
import { Center } from "./ParameterStore";
import useGenAITiles from "../hooks/useGenAIParameters.js";
import DropdownMenu from "../components/Dropdowns/DropdownMenu.jsx";
import { Dropdown } from "@dnb/eufemia";

export default function Logs() {
  const [logs, setLogs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [botName, setBotName] = useState("No filter"); // State for bot filter
  const [timeInterval, setTimeInterval] = useState(60); // State for time interval in minutes

  const { tiles } = useGenAITiles();

  const fetchLogs = () => {
    setLoading(true);
    // Get the current date and time
    let currentDate = new Date();

    // Subtract the specified time interval in milliseconds from the current date and time
    currentDate.setTime(currentDate.getTime() - timeInterval * 60000);
    console.log(currentDate.toISOString());

    api
      .get("/cloudwatch-logs", {
        params: {
          start_time: currentDate.toISOString(),
          end_time: new Date().toISOString(),
          filter_pattern: `CAP ${botName == "No filter" ? "" : '"'+botName+'"'}`,
        },
      })
      .then((res) => setLogs(res.data.logs))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    fetchLogs();
  }, []);

  const handleRefresh = () => {
    fetchLogs();
  };

  const handleBotNameChange = (botName) => {
    setBotName(botName);
  };

  const handleTimeIntervalChange = (e) => {
    setTimeInterval(e.target.value);
  };

  if (loading) {
    return (
      <Center>
        <Loader />
      </Center>
    );
  }

  return (
    <Wrapper>
      <LangWrapper>
        <Label>{t("logsPage.logs")}</Label>
        <Spacing12 />
        <Label>{t("logsPage.selectBot")}</Label>
        <DropdownMenu
          onChange={handleBotNameChange}
          selectedItem={{ content: [botName] }}
          data={[{key: "No filter"}, ...tiles].map((tile) => {return {content: [tile.key]}})}
          setLinkStatus={() => {}}
        ></DropdownMenu>
        <Spacing12 />

        <InputWrapper>
          <Label>{t("logsPage.timeInterval")}</Label>
          <StyledInput
            placeholder={t("logsPage.timeInterval")}
            value={timeInterval}
            onChange={handleTimeIntervalChange}
            rows={2}
          />
        </InputWrapper>
        <Spacing12 />
        <Button onClick={handleRefresh}>{t("logsPage.refreshLogs")}</Button>
        <Spacing12 />
        <LogsContainer>
          {logs.map((log, index) => (
            <p key={index}>{log}</p>
          ))}
        </LogsContainer>
      </LangWrapper>
    </Wrapper>
  );
}

const Spacing12 = styled.div`
  height: 12px;
`;

const LogsContainer = styled.div`
  height: 100%;
  width: 100%;
  overflow-y: scroll;
  overflow-x: scroll;
`;

const Wrapper = styled.div`
  display: flex;
  color: ${(props) => props.theme.color};
  width: 100%;
  flex-direction: row;
  padding: 1rem;
`;

const LangWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 1rem;
  gap: 0.5rem;
`;
