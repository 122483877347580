// import { trash_medium } from "@dnb/eufemia/icons";
import { useTranslation } from "react-i18next";
// import { useState } from "react";
import FilterForm from "../Forms/FilterForm";
import styled from "@emotion/styled";
export default function FilterDialog({parameter,closeDialog,initialData,indexNo}) {

  const { t } = useTranslation();

var filter="fromParent";
  return (
    <div>
    <OverlayStyle />
    <DialogWrapper>
    {initialData.name !== "" ? <TextDiv>{t("filterPage.editParentFilter")}</TextDiv> :<TextDiv>{t("filterPage.createParentFilter")}</TextDiv>}
    <FilterForm
        parentId={parameter.length+1}
        id={parameter.id}
        initialData={{ name: initialData.name, tag: initialData.tag }}
        filter={filter}
        indexNo={indexNo}
        onClose={closeDialog}
      />
    </DialogWrapper>
    </div>
  );
}

const TextDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size:26px;
  gap: 0.5rem;

  margin-bottom: 0.8rem;
  font-weight: var(--font-weight-medium);
 // font-size: 24px;
`;

const DialogWrapper = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);

  background-color: #313131;
  color: #ffffff;

  border: 1px solid white;
  border-radius: 0.625rem;
  padding: 2rem;

  gap: 1rem;

  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
`;

// const ButtonWrapper = styled.div`
//   display: flex;
//   flex-direction: column;
//   gap: 1rem;
// `;

const OverlayStyle = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.75);
  z-index: 999;
`;
