import styled from "@emotion/styled";

export const Label = styled.label`
  font-weight: var(--font-weight-medium);
  opacity: ${(props) => props.opacity};
  color: ${(props) => props.color || null};
`;

export const LightLabel = styled.label`
  font-weight: var(--font-weight-regular);
`;

export const FormWrapper = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;

  gap: 10px;
  padding: 10px;
`;

export const StyledInput = styled.input`
  background-color: #333333;
  border: 1px solid ${(props) => props.theme.color};
  color: #ffffff;
  border-radius: 0.5rem;
  min-width: 18.75rem;

  box-shadow: none;
  padding: var(--spacing-x-small);

  :focus {
    border-radius: 0.2rem;
  }
`;

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ButtonWrapper = styled.div`
  align-self: center;
`;
