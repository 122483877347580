import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const resources = {
  en: {
    translation: {
      header: {
        bots: "CHAT ADMIN PANEL",
        aino: "AINO",
        genai: "GENAI",
        juno: "JUNO",
        fix: "FIX",
        hugo: "HUGO",
        justina: "JUSTINA",
        quickmenu: "QUICK MENU",
        welcomepage: "WELCOME PAGE",
        filters: "FILTERS",
        chatmode: "CHAT MODE",
        prompt: "SYSTEM PROMPT",
        uisettings: "UI SETTINGS",
        backendSettings: "SUMMARY - BACKEND SETTINGS",
      },
      common: {
        ClickToAdd: "Click to add...",
        Rearrange: "Rearrange",
        DataUpdated: "Data updated successfully!",
        ErrorData: "Error while saving data",
        Language: "Language",
        Mode: "Mode",
        Settings: "Settings",
        Close: "Close",
        AdvancedSettings: "Advanced settings",
        EN: "EN Parameters",
        Appconfig: "Appconfig",
        Logs: "Logs",
        NO: "NO Parameters",
        Details: "Details",
        Descriptions: "Descriptions",
        NoAccess: "You do not have access to this page",
      },
      layout: {
        Back: "Back",
        LogOff: "LOG OFF",
        History: "History",
        Preview: "Preview",
        Save: "Save",
        Add: "Add",
      },
      features: {
        QuickMenu: "QUICK MENU",
        QuickMenuDescription: "Add, edit and delete links in Quick Menu.",
        WelcomePage: "WELCOME PAGE",
        WelcomePageDescription: "Change icon and text for Welcome Page.",
        Filters: "FILTERS",
        FiltersDescription: "Add, edit and delete filters.",
        ChatMode: "CHAT MODE",
        ChatModeDescription: "Turn the agent and bot mode off and on.",
        Prompt: "SYSTEM PROMPT",
        PromptDescription: "Customize the system prompt.",
        Tile: "TILES",
        TileDescription: "Customize the tiles",
      },
      welcomePage: {
        BrowseForPhoto: "Browse photos",
        Icon: "Icon",
        Text: "Text",
        MainHeader: "Main header",
        SubHeader: "Sub header",
        ClickToAdd: "Click to add...",
        DisclaimerText: "Disclaimer Text",
        DisclaimerURL: "Disclaimer URL",
      },
      summaryMenu: {
        TileToggle: "TILE",
        TileToggleDescription: "Turn tile off and on",
        UiSettings: "UI SETTINGS",
        UiSettingsDescription:
          "Edit, name, description, link in description and welcome message.",
        BackEndSettings: "BACKEND SETTINGS",
        BackEndSettingsDescription:
          "Edit backend settings like input and output settings, edit prompt.",
        AddNewTile: "Add new tile",
        DeleteTile: "DELETE TILE",
        DeleteTileDescription: "Delete tile forever...",
        Delete: "Delete",
      },
      menuPage: {
        Name: "Name",
        URL: "URL",
      },
      filterPage: {
        Subtitle: "Remember that routing tags are case sensitive.",
        FilterName: "Filter Name",
        RoutingTag: "Routing Tag",
        ParentFilterName: "Parent Filter Name",
        Tooltip: "Add Parent Filter",
        editParentFilter: "Edit Parent Filter",
        createParentFilter: "Create New Parent Filter",
        childTooltip: "Add Sub Filter",
      },
      chatModePage: {
        agent: "AGENT",
        bot: "BOT",
        description: "Turn mode off and on",
      },
      tileSwitch: {
        tile: "TILE",
        description: "Tile on and off",
      },
      promptPage: {
        type: "Type prompt here",
      },
      history: {
        add: "Added",
        edit: "Edited",
        delete: "Deleted",
      },
      uiSettingsPage: {
        tileName: "Tile Name",
        tileKey: "Tile Key",
        tipsUser: "Example user prompt",
        tipsGenai: "Example answer",
        tipsTips: "Tips",
        tileDescription: "Tile Description",
        buttonText: "Button Text",
        description: "Click to add...",
        possibleValue: "'Go', 'Coming soon' or 'Send Feedback'",
        possibleKeyValue: "Can only contain lowercase letters and hyphens",
        keyAlreadyExists: "Tilekey already in use",
        additionalInfo: "Additional Info (Only for external links)",
      },
      backendSettingsPage: {
        rag: "Rag configurations",
        prompt: "Prompt",
        msopenai: "MSOpenAI",
        index: "Index configurations",
        embedding: "Embedding configurations",
        welcomeMessageEN: "Welcome message - EN",
        welcomeMessageNO: "Velkomstmelding - NO",
        systemPrompt: "System Prompt",
        gptType: "GPT model",
        deploymentModel: "Azure resource deployment name",
        keepContext: "Remember previous messages",
        searchKey: "API key for the index in Azure AI Search",
        searchIndex: "The index in Azure AI Search",
        searchEndpoint: "The URL for Azure AI Search",
        useSearch: "Activate RAG",
        azureCognitiveSearchQueryType: "Azure Cognitive Search query type",
        topNDocuments: "Number of documents to retrieve",
        fieldsMapping: "Question-answer configuration",
        strictness: "Strictness",
        temperature: "Temperature (creativity)",
        maxTokens: "Max number of tokens",
        embeddingKey: "API key for Azure OPENAI Service",
        embeddingEndpoint: "URL for Azure OPENAI Embedding",
        maximumPageLength: "Max page-size for chunking",
        pageOverlapLength: "Overlap between chunks",
        showSources: "Show document sources",
        selectTools: "Choose tools",
        updateSearchIndex:
          "Update Azure AI Search Index. Currently only for PDF files. This will use the files in blob storage, update the files here:",
      },
      logsPage: {
        timeInterval: "Lookback period in minutes",
        refreshLogs: "Refresh Logs",
        selectBot: "Select Bot for filtering",
        logs: "Logs from CUF backend"
      },
      welcomeTitle: "Main header",
      welcomeMessage: "Sub header",
      dialogs: {
        delete: {
          title: "Are you sure you want to delete this?",
          discardText: "Cancel",
          acceptText: "Delete",
          content: "This action cannot be undone.",
          parentTitle:
            "Are you sure you permanently want to delete this parent filter and all sub filters?",
        },
        save: {
          title: "Changes have been made...",
          discardText: "Save",
          acceptText: "Discard",
          continueEditing: "Continue editing",
          content: "Would you like to save your changes or discard them ?",
        },
      },
      langswitch: {
        header: "Select language for change",
      },
    },
  },
  no: {
    translation: {
      header: {
        bots: "CHAT ADMIN PANEL",
        aino: "AINO",
        genai: "GENAI",
        juno: "JUNO",
        fix: "FIX",
        hugo: "HUGO",
        justina: "JUSTINA",
        quickmenu: "HURTIGMENY",
        welcomepage: "VELKOMSTSIDE",
        filters: "FILTRE",
        chatmode: "CHAT MODUS",
        prompt: "SYSTEMPROMPT",
        uisettings: "UI-INNSTILLINGER",
        backendSettings: "SAMMENDRAG - BAKENDINNSTILLINGER",
      },
      common: {
        ClickToAdd: "Klikk for å legge til...",
        Rearrange: "Omorganiser",
        DataUpdated: "Data oppdatert vellykket!",
        ErrorData: "Feil under lagring av data",
        Language: "Språk",
        Mode: "Mode",
        Settings: "Innstillinger",
        Close: "Lukk",
        AdvancedSettings: "Avanserte instillinger",
        EN: "EN Parameters",
        Appconfig: "Appconfig",
        Logs: "Logs",
        NO: "NO Parameters",
        Details: "Details",
        Descriptions: "Descriptions",
        NoAccess: "Du har ikke tilgang til denne siden",
      },
      layout: {
        Back: "Tilbake",
        LogOff: "LOGG AV",
        History: "Historie",
        Preview: "Forhåndsvisning",
        Save: "Lagre",
        Add: "Legg til",
      },
      features: {
        QuickMenu: "HURTIGMENY",
        QuickMenuDescription: "Legg til, endre og slett lenker i hurtigmenyen.",
        WelcomePage: "VELKOMSTSIDE",
        WelcomePageDescription: "Endre ikon og tekst på velkomstsiden.",
        Filters: "FILTRE",
        FiltersDescription: "Legg til, endre og slett filtre.",
        ChatMode: "CHAT MODUS",
        ChatModeDescription: "Slå agent- og botmodus av og på.",
        Prompt: "SYSTEMPROMPT",
        PromptDescription: "Tilpass systemforespørselen.",
      },
      welcomePage: {
        BrowseForPhoto: "Bla gjennom",
        Icon: "Ikon",
        Text: "Tekst",
        MainHeader: "Hovedoverskrift",
        SubHeader: "Undertittel",
        ClickToAdd: "Klikk for å legge til...",
        DisclaimerText: "Vilkår for bruk",
        DisclaimerURL: "Vilkår for bruk URL",
      },
      summaryMenu: {
        TileToggle: "FLIS",
        TileToggleDescription: "Slå flisen av og på",
        UiSettings: "UI-INNSTILLINGER",
        UiSettingsDescription:
          "Rediger, navn, beskrivelse, lenke i beskrivelse og velkomstmelding.",
        BackEndSettings: "BAKEND-INNSTILLINGER",
        BackEndSettingsDescription:
          "Rediger bakend-innstillinger som innskudds- og utsettings, rediger oppfordring.",
        AddNewTile: "Legg til ny flis",
        DeleteTile: "Slett modul",
        DeleteTileDescription: "Slett modul for alltid...",
        Delete: "Slett",
      },
      menuPage: {
        Name: "Navn",
        URL: "URL",
      },
      filterPage: {
        Subtitle: "Husk at rutingtagger er skriftsensitive.",
        FilterName: "Filternavn",
        RoutingTag: "Rutingtagg",
        ParentFilterName: "Hovedfilternavn",
        Tooltip: "Legg til hovedfilter",
        editParentFilter: "Endre hovedfilter",
        createParentFilter: "Create New Parent Filter",
        childTooltip: "Legg til underfilter",
      },
      chatModePage: {
        agent: "AGENT",
        bot: "BOT",
        description: "Slå modus av og på",
      },
      promptPage: {
        type: "Skriv ledetekst her",
      },
      history: {
        add: "La til",
        edit: "Redigerte",
        delete: "Slettet",
      },
      uiSettingsPage: {
        tileName: "Flisnavn",
        tileKey: "Flisnøkkel",
        tipsUser: "Example user prompt",
        tipsGenai: "Example answer",
        tipsTips: "Tips",
        tileDescription: "Flisbeskrivelse",
        buttonText: "Knappetekst",
        description: "Klikk for å legge til...",
        possibleValue: "'Gå', 'Kommer snart', 'Send tilbakemelding'",
        possibleKeyValue: "Kan kun inneholde små bokstaver og bindestrek",
        keyAlreadyExists: "Flisnøkkel allerede i bruk",
        additionalInfo: "Tilleggs informasjon (Kun for eksterne lenker)",
      },
      backendSettingsPage: {
        rag: "Rag konfigurasjoner",
        prompt: "Promts",
        msopenai: "MSOpenAI",
        index: "Index konfigurasjoner",
        embedding: "Embedding konfigurasjoner",
        welcomeMessageEN: "Welcome message - EN",
        welcomeMessageNO: "Velkomstmelding - NO",
        systemPrompt: "System Prompt",
        gptType: "Gpt-modell",
        deploymentModel: "Azure resource deployment navn",
        keepContext: "Husk eldre meldinger",
        searchKey: "API-nøkkel for indexen i Azure AI Search",
        searchIndex: "Indexen i Azure AI Search",
        searchEndpoint: "URLen for å Azure AI Search",
        useSearch: "Aktivere RAG",
        azureCognitiveSearchQueryType: "Azure Kognitiv søk-type",
        topNDocuments: "Antall dokumenter å hente",
        fieldsMapping: "Spørsmål-svar konfigurasjon",
        strictness: "Strenghet",
        temperature: "Temperature (kreativitet)",
        maxTokens: "Maks antall tokens",
        embeddingKey: "API-key for Azure OPENAI Service",
        embeddingEndpoint: "URL for Azure OPENAI Embedding",
        showSources: "Vis kilder til dokumentene",
        selectTools: "Velg verktøy",
        maximumPageLength: "Maks page-size for chunking",
        pageOverlapLength: "Overlapp mellom chunks",
        updateSearchIndex:
          "Oppdater Azure AI Search Index. Foreløpig kun for PDFer. Dette vil bruke filene i blob storage, oppdater filene her:",
      },
      logsPage: {
        timeInterval: "Hvor mange minutter tilbake skal vises?",
        refreshLogs: "Refresh Logs",
        selectBot: "Velg bot for filtrering av logs",
        logs: "Logs fra CUF backend"
      },
      welcomeTitle: "Hovedoverskrift",
      welcomeMessage: "Undertittel",
      dialogs: {
        delete: {
          title: "Er du sikker på at du vil slette dette?",
          discardText: "Avbryt",
          acceptText: "Slett",
          content: "Denne handlingen kan ikke angres.",
          parentTitle:
            "Er du sikker på at du vil slette dette hovedfilteret og tilhørende underfilter?",
        },
        save: {
          title: "Endringer har blitt gjort...",
          discardText: "Lagre",
          continueEditing: "Fortsett redigering",
          acceptText: "Forkast",
          content: "Vil du lagre endringene eller forkaste dem?",
        },
      },
      langswitch: {
        header: "Velg språk for endringer",
      },
    },
  },
};

const userLanguage = navigator.language || navigator.userLanguage;
const formattedLanguage = userLanguage.substr(0, 2).toLowerCase();

const languageMapping = {
  en: "en",
  no: "no",
};

const detectedLanguage =
  localStorage.getItem("lang") || languageMapping[formattedLanguage] || "en";

i18n.use(initReactI18next).init({
  resources,
  lng: detectedLanguage,
  fallbackLng: "en",
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
