import { useParams } from "react-router-dom";
import { CardsWrapper } from "./Features";
import FeatureCard, { DeleteCard } from "../components/Cards/FeatureCard";
import { edit, filter, star } from "@dnb/eufemia/icons";
import { useTranslation } from "react-i18next";
import ChatBotCard from "../components/Cards/ChatModeCard";
import { useEffect, useState } from "react";
import Loader from "../components/Loaders/Loader";
import api from "../apiConfig";
import toast from "react-hot-toast";
import { trash_medium } from "@dnb/eufemia/icons";
import DeleteDialog from "../components/Dialogs/DeleteDialog";
import { useNavigate } from "react-router-dom";
import useGenAITiles from "../hooks/useGenAIParameters";
import { useDispatch } from "react-redux";
import { setChanges } from "../features/changeSlice";

export default function SummaryMenu() {
  const params = useParams();
  const [isDeleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const dispatch = useDispatch();

  const { currentTile, isLoading, handleFilterPropertyChange, setIsLoading } =
    useGenAITiles();

  const { t } = useTranslation();
  const navigate = useNavigate();

  if (params.bot !== "genai") {
    return <div>Unauthorized</div>;
  }

  useEffect(() => {
    document.title = `${params.bot?.toUpperCase()}: ${params.tile}`;
  }, [params.bot]);

  // TODO: Shouldn't this happen inside UI settings? The UI Settings are the same as the filter params right?
  async function handleSave() {
    try {
      setIsLoading(true);
      for (const lang of ["en", "no"]) {
        await api.patch(
          `${params.bot}/filters/${currentTile.key}`,
          {
            display: !currentTile.display,
          },
          {
            params: {
              lang,
            },
          }
        );
        // Wait for 3 seconds before the next request
        await new Promise((resolve) => setTimeout(resolve, 3000));
      }

      toast.success(t("common.DataUpdated"));
      handleFilterPropertyChange("display", !currentTile.display);
      dispatch(setChanges(false));
    } catch (error) {
      console.log(error);
      toast.error(t("common.ErrorData"));
    } finally {
      setIsLoading(false);
    }
  }

  function handleDelete() {
    setDeleteDialogOpen(true);
  }

  async function handleRemove() {
    try {
      setIsLoading(true);
      await api.delete(`${params.bot}/filters/${currentTile.key}`);
      toast.success(t("common.DataUpdated"));
      navigate(-1);
    } catch (error) {
      toast.error(t("common.ErrorData"));
    } finally {
      setIsLoading(false);
    }
  }

  return !isLoading ? (
    <CardsWrapper>
      {currentTile && (
        <ChatBotCard
          icon={edit}
          mode={t("summaryMenu.TileToggle")}
          description={t("summaryMenu.TileToggleDescription")}
          checked={currentTile.display ? "ON" : "OFF"}
          onChange={handleSave}
        />
      )}
      <FeatureCard
        title={t("summaryMenu.UiSettings")}
        description={t("summaryMenu.UiSettingsDescription")}
        link={"ui-settings"}
        icon={filter}
      />
      {!["whisper", "feedback"].includes(currentTile.key) && (
        <FeatureCard
          title={t("summaryMenu.BackEndSettings")}
          description={t("summaryMenu.BackEndSettingsDescription")}
          link={"backend-settings"}
          icon={star}
        />
      )}
      {currentTile?.deletable && (
        <DeleteCard
          title={t("summaryMenu.DeleteTile")}
          description={t("summaryMenu.DeleteTileDescription")}
          // link={"backend-settings"}
          icon={trash_medium}
          onClick={handleDelete}
        />
      )}
      {isDeleteDialogOpen ? (
        <DeleteDialog
          handleRemove={handleRemove}
          type={""}
          closeDialog={() => setDeleteDialogOpen(false)}
        />
      ) : null}
    </CardsWrapper>
  ) : (
    <Loader />
  );
}
