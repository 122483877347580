import { useNavigate } from "react-router-dom";
import Button from "./Button";
import styled from "@emotion/styled";
import { useSelector } from "react-redux";
import { useState } from "react";
import SaveDialog from "../Dialogs/SaveDialog";
import { useTranslation } from "react-i18next";

export default function BackButton() {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const changes = useSelector((state) => state.change.changes);

  const { t } = useTranslation();

  function handleClick() {
    changes ? setOpen(true) : navigate(-1);
  }

  return (
    <BackButtonWrapper>
      <Button onClick={handleClick}>{t("layout.Back")}</Button>
      <SaveDialog open={open} setOpen={setOpen} />
    </BackButtonWrapper>
  );
}

const BackButtonWrapper = styled.div`
  position: fixed;
  left: var(--spacing-x-large);
  top: 50vh;
  z-index: 999;
`;
