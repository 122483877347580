import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  value: {
    welcomeMessageEN: "",
    welcomeMessageNO: "",
    systemPrompt: "",
    modelName: "",
    deploymentName: "",
    keepContext: "true",
    useSearch: "false",
    azureCognitiveSearchQueryType: "vector",
    topNDocuments: "2",
    fieldsMapping: "false",
    strictness: "3",
    temperature: "0.0",
    maxTokens: "3500",
    showSources: "false",
    availableTools: "",
    maximumPageLength: "2000",
    pageOverlapLength: "500",
  },
};

export const backendSettingsSlice = createSlice({
  name: "backendSettings",
  initialState,
  reducers: {
    setSetting: (state, action) => {
      const { key, value } = action.payload;
      state.value[key] = value;
    },
    setSettings: (state, action) => {
      state.value = action.payload;
    },
    resetBackendSettings: (state) => {
      state.value = initialState;
    },
  },
});

export const { setSetting, setSettings, resetBackendSettings } =
  backendSettingsSlice.actions;

export default backendSettingsSlice.reducer;
