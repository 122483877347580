/* eslint-disable */
import React, { useEffect, useState } from "react";
import api from "../apiConfig";
// import Accordion from '../components/Accordions/Accordion';
import styled from "@emotion/styled/macro";
import { InputWrapper, Label, StyledInput } from "../components/Forms/Form";
import { t } from "i18next";
import { StyledTextArea } from "../components/Forms/SettingsForm";
import Button from "../components/Buttons/Button";
import toast from "react-hot-toast";
import Loader from "../components/Loaders/Loader";
import { setChanges } from "../features/changeSlice";
import { useDispatch, useSelector } from "react-redux";
import { StyledJsonEditor } from "../components/TextEditors/StyledJsonEditor";

export default function ParameterStore() {
  const [enParameters, setEnParameters] = useState([]);
  const [noParameters, setNoParameters] = useState([]);
  const [loading, setLoading] = useState(true);
  const currentTheme = useSelector((state) => state.theme.currentTheme);

  const dispatch = useDispatch();

  useEffect(() => {
    api
      .get("genai/filters", {
        params: {
          lang: "en",
        },
      })
      .then((res) => setEnParameters(res.data.body.parameters))
      .finally(() => setLoading(false));
    api
      .get("genai/filters", {
        params: {
          lang: "no",
        },
      })
      .then((res) => setNoParameters(res.data.body.parameters))
      .finally(() => setLoading(false));
  }, []);

  const saveEn = async () => {
    setLoading(true);
    api
      .post(
        "genai/filters",
        {
          parameters: enParameters,
        },
        {
          params: {
            lang: "en",
          },
        }
      )
      .then(() => toast.success(t("common.DataUpdated")))
      .catch(() => toast.error(t("common.ErrorData")))
      .finally(() => setLoading(false));
  };

  const saveNo = async () => {
    setLoading(true);
    api
      .post(
        "genai/filters",
        {
          parameters: noParameters,
        },
        {
          params: {
            lang: "no",
          },
        }
      )
      .then(() => {
        toast.success(t("common.DataUpdated"));
        dispatch(setChanges(false));
      })
      .catch(() => toast.error(t("common.ErrorData")))
      .finally(() => setLoading(false));
  };

  if (loading) {
    return (
      <Center>
        {" "}
        <Loader />
      </Center>
    );
  }

  return (
    <Wrapper>
      <LangWrapper>
        <Label>{t("common.EN")}</Label>
        <Spacing12 />
        <StyledJsonEditor
          data={enParameters}
          maxWidth={10000}
          currentTheme={currentTheme}
          onUpdate={({ newData }) => {
            dispatch(setChanges(true));
            setEnParameters(newData);
          }}
        />
        <Button onClick={saveEn}>{t("layout.Save")}</Button>
      </LangWrapper>
      <LangWrapper>
        <Label>{t("common.NO")}</Label>
        <Spacing12 />
        <StyledJsonEditor
          data={noParameters}
          maxWidth={10000}
          currentTheme={currentTheme}
          onUpdate={({ newData }) => {
            dispatch(setChanges(true));
            setNoParameters(newData);
          }}
        />
        <Button onClick={saveNo}>{t("layout.Save")}</Button>
      </LangWrapper>
    </Wrapper>
  );
}

export const Center = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;

const Spacing12 = styled.div`
  height: 12px;
`;

const Wrapper = styled.div`
  display: flex;
  color: ${(props) => props.theme.color};
  width: 100%;
  flex-direction: row;
  padding: 1rem;
`;
const LangWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 1rem;
  gap: 0.5rem;
`;

// const StyledJsonEditor = styled(JsonEditor)`
//   appearance: none;
//   border: none;
//   margin: 0;
//   padding: 16px;
//   outline: none;
//   resize: ${(props) => props.resize || 'none'};
//   overflow-x: hidden;
//   overflow-y: ${(props) => props.overflowY || 'hidden'};
//   width: 100%;
//   height: 100%;
//   background-color: ${(props) => props.theme.buttonBackgroundColor};
//   border: 1px solid ${(props) => props.theme.color};
//   color: #ffffff;
//   border-radius: 0.5rem;

//   &:focus {
//     border-radius: 0.2rem;
//   }
// `;
