import { configureStore } from "@reduxjs/toolkit";
import themeReducer from "../features/themeSlice";
import filtersReducer from "../features/filtersSlice";
import menuReducer from "../features/menuSlice";
import welcomeReducer from "../features/welcomeSlice";
import changeReducer from "../features/changeSlice";
import langReducer from "../features/langSlice";
import chatmodeReducer from "../features/chatModeSlice";
import promptReducer from "../features/promptSlice";
import botsReducer from "../features/botsSlice";
import uiSettingsReducer from "../features/uisettingsSlice";
import backendSettingsReducer from "../features/backendSettingsSlice";
import tilesReducer from "../features/tiles";

export default configureStore({
  reducer: {
    theme: themeReducer,
    filters: filtersReducer,
    menu: menuReducer,
    welcome: welcomeReducer,
    chatmode: chatmodeReducer,
    change: changeReducer,
    lang: langReducer,
    prompt: promptReducer,
    bots: botsReducer,
    uisettings: uiSettingsReducer,
    backendSettings: backendSettingsReducer,
    tiles: tilesReducer,
  },
});
