import styled from "@emotion/styled/macro";
import { JsonEditor } from "json-edit-react";

export const StyledJsonEditor = styled(({ currentTheme, ...props }) => (
  <JsonEditor {...props} theme={currentTheme == "dark" ? "githubDark" : "githubLight"}/>
))`
  appearance: none;
  border: none;
  margin: 0;
  padding: 24px;
  outline: none;
  resize: ${(props) => props.resize || "none"};
  overflow-x: hidden;
  overflow-y: auto; /* Enable vertical scrolling */
  width: 100%;
  height: 100%;
  border-radius: 0.5rem;

  /* Ensure the editor content is scrollable */
  .editor-content {
    height: 100%;
    overflow-y: auto;
  }
`;
