import styled from "@emotion/styled/macro";
import Header from "../components/Header/Header";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import { useTheme } from "@emotion/react";
import { useDispatch, useSelector } from "react-redux";
import toast, { Toaster } from "react-hot-toast";
import api from "../apiConfig";
import { useEffect, useState } from "react";
import Loader from "../components/Loaders/Loader";
import SaveDialog from "../components/Dialogs/SaveDialog";
import Button from "../components/Buttons/Button";
import { setChanges } from "../features/changeSlice";
import { t } from "i18next";

export default function AdvancedGenaiSettings() {
  const theme = useTheme();
  const colorMode = useSelector((state) => state.theme.currentTheme);
  const [loading, setLoading] = useState();
  const changes = useSelector((state) => state.change.changes);
  const navigate = useNavigate();
  const [routeAttemptingToBeNavigated, setRouteAttempingToBeNavigated] =
    useState();
  const dispatch = useDispatch();
  const [showAdvancedSettings, setShowAdvancedSettings] = useState();

  const [open, setOpen] = useState();

  const themeColorMode = colorMode === "light" ? theme.light : theme.dark;

  useEffect(() => {
    api
      .get("/genai-superusers")
      .then((res) => setShowAdvancedSettings(res.status === 200))
      .catch(() => setShowAdvancedSettings(false));
  }, []);

  const location = useLocation();

  const clearCache = async () => {
    setLoading(true);
    api
      .post("clear-cache", { keys: ["parameterStore", "GENAI_CONFIGS"] })
      .then(() => toast.success("Successfully cleared cache"))
      .catch(() => toast.error("Could not clear cache"))
      .finally(() => setLoading(false));
  };

  if (!showAdvancedSettings) {
    return (
      <Wrapper theme={themeColorMode}>
        <ContentWrapper theme={themeColorMode}>
          <NoAccessWrapper>
            <NoAccessText>{t("common.NoAccess")}</NoAccessText>
          </NoAccessWrapper>
        </ContentWrapper>
      </Wrapper>
    );
  }

  return (
    <Wrapper theme={themeColorMode}>
      <ContentWrapper theme={themeColorMode}>
        <SideBar>
          <TopHeader>
            <StyledHeader />
          </TopHeader>
          {!loading ? (
            <>
              <Button
                active={
                  location.pathname.split("/")[
                    location.pathname.split("/").length - 1
                  ] == "parameter-store"
                }
                onClick={() => {
                  if (changes) {
                    setOpen(true);
                    setRouteAttempingToBeNavigated("parameter-store");
                  } else {
                    navigate("parameter-store");
                  }
                }}
              >
                Filters
              </Button>
              <Button
                active={
                  location.pathname.split("/")[
                    location.pathname.split("/").length - 1
                  ] == "appconfig"
                }
                onClick={() => {
                  if (changes) {
                    setOpen(true);
                    setRouteAttempingToBeNavigated("appconfig");
                  } else {
                    navigate("appconfig");
                  }
                }}
              >
                Appconfig
              </Button>
              <Button
                active={
                  location.pathname.split("/")[
                    location.pathname.split("/").length - 1
                  ] == "tools"
                }
                onClick={() => {
                  if (changes) {
                    setOpen(true);
                  } else {
                    navigate("tools");
                    setRouteAttempingToBeNavigated("tools");
                  }
                }}
              >
                Tools
              </Button>
              <Button
                active={
                  location.pathname.split("/")[
                    location.pathname.split("/").length - 1
                  ] == "logs"
                }
                onClick={() => {
                  if (changes) {
                    setOpen(true);
                  } else {
                    navigate("logs");
                    setRouteAttempingToBeNavigated("logs");
                  }
                }}
              >
                Logs
              </Button>
              <ClearCacheButton green={true} onClick={clearCache}>
                Clear cache
              </ClearCacheButton>
            </>
          ) : (
            <Loader />
          )}
          <BottomNavigation>
            <Button
              onClick={() => {
                if (changes) {
                  return setOpen(true);
                }
                navigate("/");
              }}
            >
              Close
            </Button>
          </BottomNavigation>
        </SideBar>
        <Outlet />
      </ContentWrapper>
      <Toaster
        position="bottom-center"
        toastOptions={{
          style: {
            border: `1px solid white`,
            borderRadius: "8px",
            background: "#313131",
            color: "white",
          },
        }}
      />
      <SaveDialog
        customSaveText={"dialogs.save.continueEditing"}
        onSave={() => {
          setOpen(false);
          return;
        }}
        onDiscard={() => {
          setOpen(false);
          navigate(routeAttemptingToBeNavigated);
          dispatch(setChanges(false));
        }}
        open={open}
        setOpen={setOpen}
      />
    </Wrapper>
  );
}

const TopHeader = styled.div`
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  top: 1rem;
`;

const NoAccessWrapper = styled.div`
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
`;

const NoAccessText = styled.p`
  color: ${(props) => props.theme.color};
  font-size: var(--font-size-large);
`;

const StyledHeader = styled(Header)`
  position: absolute;
  top: 10rem;
`;
const SideBar = styled.div`
  display: flex;
  overflow: hidden;
  gap: 1rem;
  border-right: solid;
  position: relative;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  width: 12rem;
`;

const BottomNavigation = styled.div`
  justify-content: center;
  align-items: center;
  display: flex;
  flex-grow: 1;
  position: absolute;
  width: calc(100% - 2rem);
  bottom: 1rem;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100dvw;
  height: 100dvh;
  overflow: hidden;
  background-color: ${(props) => props.theme.backgroundColor};
`;
const ContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100dvw;
  height: 100%;
  overflow: hidden;
  background-color: ${(props) => props.theme.backgroundColor};
`;

const ClearCacheButton = styled(Link)`
  display: flex;
  padding: 0.5rem;
  justify-content: center;
  color: var(--color-summer-green);
  width: 100%;
  align-items: center;
  border-radius: 100rem;
  background-color: ${(props) =>
    props.active ? props.theme.hoverColor : props.theme.buttonBackgroundColor};
  text-decoration: none;
  border: solid;
  border-color: ${(props) =>
    props.active ? props.theme.hoverBorderColor : "transparent"};
  &:hover {
    border: solid;
    background-color: ${(props) => props.theme.hoverColor};
    border-color: ${(props) => props.theme.hoverBorderColor};
  }
`;