import styled from "@emotion/styled/macro"

export default function WelcomeAdvancedSettings() {
  return (
    <StyledWelcomeAdvancedWrapper>Welcome to advanced settings</StyledWelcomeAdvancedWrapper>
  )
}

const StyledWelcomeAdvancedWrapper = styled.div`
    display: flex;
    padding: 1rem;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    color: ${props => props.theme.color};
    font-size: x-large;
`