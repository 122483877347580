import { Icon } from "@dnb/eufemia";
import { chevron_down } from "@dnb/eufemia/icons";
import styled from "@emotion/styled";
import { useEffect, useState } from "react";

export default function Accordion({ title, subtitleList, children, setParentIsOpen }) {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (setParentIsOpen === undefined) return;
    setParentIsOpen(isOpen)
  }, [isOpen])

  if (subtitleList.length === 0) {
    return (
      <AccordionWrapper>
        <Title>{title} {children}</Title>
      </AccordionWrapper>
    );
  }

  return (
    <AccordionWrapper>
      <AccordionTitle onClick={() => setIsOpen(!isOpen)}>
        <StyledIcon isOpen={isOpen} icon={chevron_down} size={"medium"} />
        <div>
          <Title>{title}</Title>
          {subtitleList.map((value, index) => {
            return <div key={index}>{value}</div>;
          })}
        </div>
      </AccordionTitle>
      <AccordionContent isOpen={isOpen}>
        <AccordionContentInner isOpen={isOpen}>
          {children}
        </AccordionContentInner>
      </AccordionContent>
    </AccordionWrapper>
  );
}

const AccordionWrapper = styled.div`
  overflow: hidden;
  margin-bottom: 10px;

  & ::-webkit-scrollbar {
    width: 8px;
  }

  & ::-webkit-scrollbar-track {
    background: ${(props) => props.theme.backgroundColor};
    border-radius: 50px;
  }

  & ::-webkit-scrollbar-thumb {
    background: #ffffff;
    border-radius: 50px;
  }

  & ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

const AccordionTitle = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;

  gap: 8px;
`;

const StyledIcon = styled(Icon)`
  transform: ${(props) => (props.isOpen ? "rotate(0deg)" : "rotate(-90deg)")};
  transition: transform 0.3s ease-in-out;
`;

const Title = styled.div`
  font-weight: bold;
`;

const AccordionContentInner = styled.div`
  overflow-y: ${(props) => (props.isOpen ? "auto" : "hidden")};
  overflow-x: clip;
  max-height: ${(props) => (props.isOpen ? "300px" : "0")};
`;

const AccordionContent = styled.div`
  margin-top: 10px;
  transition: max-height 0.3s ease-in-out;
  display: block;
  max-height: ${(props) => (props.isOpen ? "300px" : "0")};
  overflow: hidden;
`;
