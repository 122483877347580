import styled from "@emotion/styled";
import { add, folder } from "@dnb/eufemia/icons";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import api from "../apiConfig";
import FeatureCard, { NewFeatureCard } from "../components/Cards/FeatureCard";
import Loader from "../components/Loaders/Loader";
import { useTranslation } from "react-i18next";
import { setTiles } from "../features/tiles";

const newTileFlag = true;

export default function Tiles() {
  const { t } = useTranslation();

  const tiles = useSelector((state) => state.tiles.infos);
  const dispatch = useDispatch();

  const params = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const lang = useSelector((state) => state.lang.lang);

  useEffect(() => {
    document.title = `${params.bot? params.bot?.toUpperCase() : ''}: Tiles`; 
  }, [params.bot]);

  useEffect(() => {
    let isMounted = true; // fix: Can't perform a React state update on an unmounted component
    setIsLoading(true);
    api
      .get(`${params.bot}/filters`, {
        params: {
          lang: lang,
        },
      })
      .then((res) => {
        if (isMounted) {
          dispatch(setTiles(res.data.body.parameters));
          setIsLoading(false);
        }
      })
      .catch((err) => {
        toast.error("Unable to load tiles");
        console.error(err);
        setIsLoading(false);
      });

    return () => {
      isMounted = false;
    };
  }, [params.bot, lang]);

  return !isLoading ? (
    <CardsWrapper>
      {tiles &&
        tiles.map((tile) => {
          return (
            <FeatureCard
              key={tile.key}
              title={tile.displayName?.toUpperCase()}
              description={tile.description}
              link={`${tile.key}`}
              icon={folder}
            />
          );
        })}
      {newTileFlag && (
        <NewFeatureCard
          description={t("summaryMenu.AddNewTile")}
          // link={"new"}
          link={"new/ui-settings"}
          icon={add}
        />
      )}
    </CardsWrapper>
  ) : (
    <Loader />
  );
}

const CardsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  max-width: 76vw;
  gap: 4rem;

  @media screen and (max-width: 1300px) {
    gap: 2rem;
  }

  @media screen and (max-height: 1000px) {
    margin-top: var(--spacing-large);
  }
`;
