import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
// import HistoryButton from "../components/Buttons/HistoryButton";
import Loader from "../components/Loaders/Loader";
import { useTranslation } from "react-i18next";

import { Label, FormWrapper, InputWrapper } from "../components/Forms/Form.jsx";
import SettingsAccordion from "../components/Accordions/SettingsAccordion.jsx";
import {
  // ButtonWrapper,
  ColumnDiv,
  ColumsWrapper,
  ContentWrapper,
  Divider,
  PageWrapper,
  SaveButtonWrapper,
  StyledTextArea,
  TextDiv,
  StyledInput,
} from "../components/Forms/SettingsForm.jsx";
import styled from "@emotion/styled";
import Button from "../components/Buttons/Button.jsx";
import useGenAITiles from "../hooks/useGenAIParameters.js";
import QueriesLangSwitch from "../components/Switchs/QueriesLangSwitch.jsx";

const regexFilterKey = /^([a-z-]*)$/;

export default function UiSettings() {
  const params = useParams();
  const [isNewTile, setIsNewTile] = useState();
  const [keyAlreadyExists, setKeyAlreadyExists] = useState();
  const [invalidKey, setInvalidKey] = useState();

  const {
    tiles,
    currentTile,
    isLoading,
    handleFilterPropertyChange,
    fetchedParameters,
    setIsLoading,
    saveFilterChanges,
    createNewFilter,
  } = useGenAITiles();

  useEffect(() => {
    if (fetchedParameters.length > 0 && isNewTile) {
      setKeyAlreadyExists(
        fetchedParameters
          .map((filter) => filter.key)
          .indexOf(currentTile.key) !== -1
      );
    } else {
      setKeyAlreadyExists(false);
    }
  }, [fetchedParameters, tiles]);

  useEffect(() => {
    if (params.tile === "new") {
      setIsNewTile(true);
    }
  }, []);

  useEffect(() => {
    if (!currentTile) return;
    if (currentTile.key) {
      setInvalidKey(!regexFilterKey.test(currentTile.key));
    }
  }, [currentTile]);

  const { t } = useTranslation();

  const changes = useSelector((state) => state.change.changes);

  useEffect(
    () => (document.title = `${params.tile?.toUpperCase()}: UI Settings`),
    []
  );

  async function handleSave() {
    setIsLoading(true);
    if (isNewTile) {
      await createNewFilter();
      setIsNewTile(false);
    } else {
      await saveFilterChanges();
    }
    setIsLoading(false);
  }

  // const customChangeLangFunction = () => {
  //   setChecked(checked ? false : true);
  //   dispatch(toggleLang());
  // };

  return !isLoading && currentTile && tiles ? (
    <PageWrapper>
      <ContentWrapper>
        <QueriesLangSwitch customHandleSave={handleSave} />
        <ColumsWrapper>
          <ColumnDiv>
            <TextDiv>
              <FormWrapper>
                <InputWrapper>
                  <Label opacity={isNewTile ? 1 : 0.4}>
                    {t("uiSettingsPage.tileKey")}
                  </Label>
                  <StyledInput
                    opacity={isNewTile ? 1 : 0.4}
                    disabled={!isNewTile}
                    placeholder={t("uiSettingsPage.description")}
                    value={currentTile.key}
                    onChange={(e) => {
                      handleFilterPropertyChange("key", e.target.value);
                    }}
                  />
                  {keyAlreadyExists && (
                    <PossibleValue color="red">
                      {t("uiSettingsPage.keyAlreadyExists")}
                    </PossibleValue>
                  )}
                  {invalidKey && (
                    <PossibleValue color="red">
                      {t("uiSettingsPage.possibleKeyValue")}
                    </PossibleValue>
                  )}
                </InputWrapper>
                <InputWrapper>
                  <Label>{t("uiSettingsPage.tileName")}</Label>
                  <StyledInput
                    placeholder={t("uiSettingsPage.description")}
                    value={currentTile.displayName}
                    onChange={(e) =>
                      handleFilterPropertyChange("displayName", e.target.value)
                    }
                  />
                </InputWrapper>
                <InputWrapper>
                  <Label>{t("uiSettingsPage.buttonText")}</Label>
                  <StyledInput
                    placeholder={t("uiSettingsPage.description")}
                    value={currentTile.buttonText}
                    onChange={(e) =>
                      handleFilterPropertyChange("buttonText", e.target.value)
                    }
                    rows={2}
                  />
                  <PossibleValue>
                    {t("uiSettingsPage.possibleValue")}
                  </PossibleValue>
                </InputWrapper>
              </FormWrapper>
            </TextDiv>
          </ColumnDiv>
          <Divider />
          <ColumnDiv>
            <Spacing />
            <TextDiv>
              <FormWrapper>
                <SettingsAccordion title={t("uiSettingsPage.tileDescription")}>
                  <StyledTextArea
                    placeholder={t("uiSettingsPage.description")}
                    value={currentTile.description}
                    rows={4}
                    onChange={(e) =>
                      handleFilterPropertyChange("description", e.target.value)
                    }
                  />
                </SettingsAccordion>

                <InputWrapper>
                  <Label>{t("uiSettingsPage.additionalInfo")}</Label>
                  <StyledInput
                    placeholder={t("uiSettingsPage.description")}
                    value={currentTile.additionalInfo}
                    onChange={(e) =>
                      handleFilterPropertyChange(
                        "additionalInfo",
                        e.target.value
                      )
                    }
                  />
                </InputWrapper>
                <InputWrapper>
                  <Label>{t("uiSettingsPage.tipsTips")}</Label>
                  <StyledInput
                    placeholder={t("uiSettingsPage.description")}
                    value={currentTile.tileTipsTips}
                    onChange={(e) =>
                      handleFilterPropertyChange("tileTipsTips", e.target.value)
                    }
                  />
                </InputWrapper>
                <InputWrapper>
                  <Label>{t("uiSettingsPage.tipsUser")}</Label>
                  <StyledInput
                    placeholder={t("uiSettingsPage.description")}
                    value={currentTile.tileTipsUser}
                    onChange={(e) =>
                      handleFilterPropertyChange("tileTipsUser", e.target.value)
                    }
                  />
                </InputWrapper>
                <InputWrapper>
                  <Label>{t("uiSettingsPage.tipsGenai")}</Label>
                  <StyledInput
                    placeholder={t("uiSettingsPage.description")}
                    value={currentTile.tileTipsGenai}
                    onChange={(e) =>
                      handleFilterPropertyChange(
                        "tileTipsGenai",
                        e.target.value
                      )
                    }
                  />
                </InputWrapper>
              </FormWrapper>
            </TextDiv>
          </ColumnDiv>
        </ColumsWrapper>
        <SaveButtonWrapper>
          {/* <SaveButton style={{ width: "auto" }} disabled={!changes} /> */}
          <Button
            variant="secondary"
            type="submit"
            onClick={() => handleSave()}
            disabled={
              !changes ||
              keyAlreadyExists ||
              currentTile.key.length === 0 ||
              !regexFilterKey.test(currentTile.key)
            }
          >
            {t("layout.Save")}
          </Button>
        </SaveButtonWrapper>
      </ContentWrapper>
      {/* <ButtonWrapper> */}
        {/* <HistoryButton /> */}
      {/* </ButtonWrapper> */}
    </PageWrapper>
  ) : (
    <Loader />
  );
}

const Spacing = styled.div`
  height: 7px;
`;
const PossibleValue = styled.div`
  font-size: 14px;
  color: ${(props) => props.color};
`;
