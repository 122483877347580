import { chat, confetti, folder, list } from "@dnb/eufemia/icons";
import styled from "@emotion/styled";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import api from "../apiConfig";
import FeatureCard from "../components/Cards/FeatureCard";
import Loader from "../components/Loaders/Loader";
import Tiles from "./Tiles";

export default function Features() {
  const [cards, setCards] = useState([]);
  const params = useParams();
  const [isLoading, setIsLoading] = useState(false);

  const { t } = useTranslation();

  useEffect(() => document.title = `${params.bot?.toUpperCase()}`, [])


  useEffect(() => {
    let isMounted = true; // fix: Can't perform a React state update on an unmounted component
    setIsLoading(true);
    // Fetching the menu cards
    api
      .get(`${params.bot}/features`)
      .then((res) => {
        if (isMounted) {
          setCards(res.data.body);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        toast.error("Unable to load features")
        console.error(err);
        setIsLoading(false);
      });

    return () => {
      isMounted = false;
    };
  }, [params.bot]);

  return !isLoading ? (
    <CardsWrapper>
      {cards.includes("menu") && (
        <FeatureCard
          title={t("features.QuickMenu")}
          description={t("features.QuickMenuDescription")}
          link={"quick-menu"}
          icon={list}
        />
      )}
      {cards.includes("welcome") && (
        <FeatureCard
          title={t("features.WelcomePage")}
          description={t("features.WelcomePageDescription")}
          link={"welcome-page"}
          icon={confetti}
        />
      )}
      {cards.includes("filters") && (
        <FeatureCard
          title={t("features.Filters")}
          description={t("features.FiltersDescription")}
          link={"filters"}
          icon={folder}
        />
      )}
      {cards.includes("chatMode") && (
        <FeatureCard
          title={t("features.ChatMode")}
          description={t("features.ChatModeDescription")}
          link={"chat-mode"}
          icon={chat}
        />
      )}
      {cards.includes("prompt") && (
        <FeatureCard
          title={t("features.Prompt")}
          description={t("features.PromptDescription")}
          link={"prompt"}
          icon={chat}
        />
      )}
      {cards.includes("tiles") && (
        <Tiles />
      )}
    </CardsWrapper>
  ) : (
    <Loader />
  );
}

export const CardsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  max-width: 76vw;
  gap: 4rem;

  @media screen and (max-width: 1300px) {
    gap: 2rem;
  }

  @media screen and (max-height: 1000px) {
    margin-top: var(--spacing-large);
  }
`;
