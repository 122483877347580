import styled from "@emotion/styled";
import { useState } from "react";
import { toast } from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import api from "../../apiConfig";
import { transformItem } from "../../pages/Menu";
import Button from "./Button";
import HistoryDrawerContent from "../HistoryDrawerContent/HistoryDrawerContent";

export default function HistoryButton() {
  const [isOpen, setIsOpen] = useState(false);
  const [history, setHistory] = useState();
  const lang = useSelector((state) => state.lang.queriesLang);
  const filter = useSelector((state) => state.menu.currentFilter);

  const { t } = useTranslation();
  
  const params = useParams();
  const location = useLocation();
  const currentPath = location.pathname.split("/").pop();

  function handleOnClick() {
    let url;
    let paramsObj = {
      lang: lang,
    };

    switch (currentPath) {
      case "quick-menu":
        url = `${params.bot}/menu/history`;
        paramsObj.filter = transformItem(filter);
        break;
      case "filters":
        url = `${params.bot}/filters/history`;
        break;
      case "welcome-page":
        url = `${params.bot}/welcome/history`;
        break;
      case "chat-mode":
        url = `${params.bot}/chat-mode/history`;
        break;
        case "prompt":
          url = `${params.bot}/prompt/history`;
          break;
        case "backend-settings":
          url = "appconfig-history"
          break;
      default:
        break;
    }
    api
      .get(url, {
        params: paramsObj,
      })
      .then((res) => {
        setHistory(res.data);   
      })
      .catch(() => {  
        toast.error("Unable to load history");
      });
    setIsOpen(true);
  }

  return (
    <>
      <Button onClick={handleOnClick}>{t("layout.History")}</Button>
      <Overlay isOpen={isOpen} onClick={() => setIsOpen(false)} />
      <DrawerWrapper isOpen={isOpen}>
        <HistoryDrawerContent history={history} handleClose={() => setIsOpen(false)} currentPath={currentPath}/>
      </DrawerWrapper>
    </>
  );
}

const DrawerWrapper = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  width: 33%;
  height: 100%;
  background-color: #313131;
  color: #ffffff;
  overflow: auto;
  transform: translateX(${(props) => (props.isOpen ? "0" : "100%")});
  opacity: ${(props) => (props.isOpen ? "1" : "0")};
  transition:
    transform 0.3s ease-in-out,
    opacity 0.3s ease-in-out;
  z-index: 1000;

  padding: var(--spacing-large);
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  opacity: ${(props) => (props.isOpen ? "1" : "0")};
  transition: opacity 0.3s ease-in-out;
  pointer-events: ${(props) => (props.isOpen ? "auto" : "none")};
  z-index: 999;
`;
