import { createSlice } from "@reduxjs/toolkit";

export const tileSwitchSlice = createSlice({
  name: "tiles",
  initialState: {
    infos: [],
    tile: null,
  },
  reducers: {
    setTiles: (state, action) => {
      state.infos = action.payload;
    },
    setTile: (state, action) => {
      const tile = state.infos.find(
        (tile) => tile.key === action.payload
      );

      if (tile) {
        state.tile = tile;
      }
      else {
        state.tile = {key: action.payload}
        // throw new Error(`Tile with value ${action.payload} not found when settinig tile`);
      }
    },
      setTileMode: (state, action) => {
      const { key, shouldDisplay } = action.payload;
      const tileIndex = state.infos.findIndex(
        (tile) => tile.key === key 
      );

      if (tileIndex !== -1) {
        state.infos[tileIndex].display = shouldDisplay;
        if (state.tile && state.tile.key === key) {
          state.tile.display = shouldDisplay;
        }
      }
    },
    deleteTiles: (state, action) => {
      
      const tileIndex = state.infos.findIndex(
        (tile) => tile.key.toLowerCase().replace(/\s/g,'') === action.payload.key.toLowerCase().replace(/\s/g,'')
      );
      state.infos.splice(tileIndex, 1);

    },
  },
});

export const { addTile, setTiles, setTile, setTileMode,deleteTiles } = tileSwitchSlice.actions;
export default tileSwitchSlice.reducer;
