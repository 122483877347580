/* eslint-disable */
import React, { useEffect, useState } from "react";
import api from "../apiConfig";
// import Accordion from '../components/Accordions/Accordion';
import styled from "@emotion/styled/macro";
import { InputWrapper, Label, StyledInput } from "../components/Forms/Form";
import { t } from "i18next";
import { Textarea } from "@dnb/eufemia";
import { StyledTextArea } from "../components/Forms/SettingsForm";
import Button from "../components/Buttons/Button";
import toast from "react-hot-toast";
import { Center } from "./ParameterStore";
import Loader from "../components/Loaders/Loader";
import { useDispatch, useSelector } from "react-redux";
import { setChanges } from "../features/changeSlice";
import { StyledJsonEditor } from "../components/TextEditors/StyledJsonEditor";

export default function Appconfig() {
  const [appconfig, setAppconfig] = useState({});
  const [loading, setLoading] = useState(true);

  const dispatch = useDispatch();
  const currentTheme = useSelector((state) => state.theme.currentTheme);

  useEffect(() => {
    api
      .get("/appconfig", {
        params: {
          lang: "en",
        },
      })
      .then((res) => setAppconfig(res.data))
      .finally(() => setLoading(false));
  }, []);

  const save = async () => {
    api
      .post(
        "/appconfig",
        {
          replaceAll: true,
          is_new_key: false,
          backendSettings: appconfig,
          isNewKey: false,
          key: "",
        },
        {
          params: {
            lang: "en",
          },
        }
      )
      .then(() => {
        toast.success(t("common.DataUpdated"));
        dispatch(setChanges(false));
      })
      .catch(() => toast.error(t("common.ErrorData")))
      .finally(() => setLoading(false));
  };

  if (loading) {
    return (
      <Center>
        {" "}
        <Loader />
      </Center>
    );
  }

  return (
    <Wrapper>
      <LangWrapper>
        <Label>{t("common.Appconfig")}</Label>
        <Spacing12 />
        <StyledJsonEditor
          data={appconfig}
          maxWidth={10000}
          currentTheme={currentTheme}
          onUpdate={({ newData }) => {
            dispatch(setChanges(true));
            setAppconfig(newData);
          }}
        />
        <Button onClick={save}>{t("layout.Save")}</Button>
      </LangWrapper>
    </Wrapper>
  );
}

const Spacing12 = styled.div`
  height: 12px;
`;

const Wrapper = styled.div`
  display: flex;
  color: ${(props) => props.theme.color};
  width: 100%;
  flex-direction: row;
  padding: 1rem;
`;
const LangWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 1rem;
  gap: 0.5rem;
`;

const ParameterStoreWrapper = styled.pre`
  display: flex;
  max-width: 50%;
  white-space: pre-wrap;
`;
