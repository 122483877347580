/* eslint-disable no-unused-vars */
import { toast } from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import api from "../apiConfig";
import { setChanges } from "../features/changeSlice";
import { transformItem } from "../pages/Menu";
import store from "../store/store";
import { initialState } from "../features/backendSettingsSlice";
const useHandleSave = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const location = useLocation();
  // const currentPath = location.pathname.split("/").pop();
  const { t } = useTranslation();

  const links = useSelector((state) => state.menu.links);
  const currentFilter = useSelector((state) => state.menu.currentFilter);
  const filters = useSelector((state) => state.filters.filters);
  const welcome = useSelector((state) => state.welcome);
  const chatmode = useSelector((state) => state.chatmode);
  const prompt = useSelector((state) => state.prompt);
  const backendSettings = useSelector((state) => state.backendSettings.value);
  const uiSettings = useSelector((state) => state.uisettings);
  const lang = useSelector((state) => state.lang.queriesLang);
  //const isNewKey = location.pathname.split("/")[location.pathname.split("/").length - 2] === 'new'
  const currentPath = location.pathname.split("/").pop();

  const removeIdFromParameters = (parameters) =>
    parameters.map((item) => {
      const { id, ...itemWithoutId } = item;
      return {
        ...itemWithoutId,
        child_parameters: item.child_parameters?.map((child) => {
          const { id, ...childWithoutId } = child;
          return childWithoutId;
        }),
      };
    });

  const handleSave = async (isNewKey, tileKey) => {
    let endpoint = "";
    let data = null;
    let updatedState = null;
    let parameters = null;
    if (currentPath === "ui-settings") {
      dispatch(setChanges(false));
      updatedState = store.getState();
      parameters = updatedState.uisettings.parameters;
      try {
        // We need to update the values in both languages
        await api.post(
          `${params.bot}/filters`,
          {
            parameters,
          },
          {
            params: {
              lang: "en",
            },
          }
        );
        await api.post(
          `${params.bot}/filters`,
          {
            parameters,
          },
          {
            params: {
              lang: "no",
            },
          }
        );
        if (isNewKey) {
          await api.post(
            "/appconfig",
            {
              key: tileKey,
              backendSettings: initialState.value,
              isNewKey: true,
              replaceAll: false,
            },
            {
              params: {
                lang: lang,
              },
            }
          );
        }
        toast.success(t("common.DataUpdated"));
      } catch (error) {
        toast.error(t("common.ErrorData"));
      }
    }
    switch (currentPath) {
      case "quick-menu":
        endpoint = `${params.bot}/menu`;
        data = {
          parameters: removeIdFromParameters(links.parameters),
        };
        break;
      case "filters":
        endpoint = `${params.bot}/filters`;
        data = {
          parameters: removeIdFromParameters(filters.parameters),
        };
        break;
      case "welcome-page":
        endpoint = `${params.bot}/welcome`;
        data = welcome.infos;
        break;
      case "chat-mode":
        endpoint = `${params.bot}/chat-mode`;
        data = chatmode;
        break;
      case "prompt":
        endpoint = `${params.bot}/prompt`;
        data = prompt;
        break;
      case "backend-settings":
        endpoint = `/appconfig`;
        data = {
          key: params.tile,
          backendSettings: backendSettings,
          isNewKey: false,
          replaceAll: false,
        };
        break;

      default:
        return;
    }

    try {
      await api.post(endpoint, data, {
        params: {
          lang: lang,
          filter:
            currentPath === "quick-menu"
              ? transformItem(currentFilter)
              : undefined,
        },
      });
      toast.success(t("common.DataUpdated"));
    } catch (error) {
      toast.error(t("common.ErrorData"));
    }

    dispatch(setChanges(false));
  };

  return handleSave;
};

export default useHandleSave;
