import styled from "@emotion/styled";
import { useEffect, useMemo, useRef, useState } from "react";
import toast, { LoaderIcon } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import api from "../apiConfig";
import Button from "../components/Buttons/Button";
import SaveButton from "../components/Buttons/SaveButton";
import Loader from "../components/Loaders/Loader";
import { useTranslation } from "react-i18next";
import {
  ColumnDiv,
  ColumsWrapper,
  ContentWrapper,
  Divider,
  PageWrapper,
  SaveButtonWrapper,
  StyledInput,
  StyledTextArea,
  TextDiv,
} from "../components/Forms/SettingsForm.jsx";
import { InputWrapper, Label, LightLabel } from "../components/Forms/Form.jsx";
import {
  setSetting,
  setSettings,
  resetBackendSettings,
} from "../features/backendSettingsSlice.js";
import SettingsAccordion from "../components/Accordions/SettingsAccordion.jsx";
import DropdownMenu from "../components/Dropdowns/DropdownMenu.jsx";
import { Link, Switch } from "@dnb/eufemia";
import MultiSelectDropdownMenu from "../components/Dropdowns/MulitSelectDropdownMenu.jsx";

export default function BackendSettings() {
  const dispatch = useDispatch();
  const params = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [isIndexCreated, setIsIndexCreated] = useState(false);
  const [isIndexing, setIsIndexing] = useState(false);
  const [isCreatingIndex, setIsCreatingIndex] = useState(false);
  const [azureOpenAIDeployments, setAzureOpenAIDeployments] = useState([]);
  const [toolsArray, setToolsArray] = useState([]);
  const [blobStorageUrl, setBlobStorageUrl] = useState("");
  const mounted1 = useRef();
  const mounted2 = useRef();

  const { t } = useTranslation();

  const settings = useSelector((state) => state.backendSettings.value);

  const lang = useSelector((state) => state.lang.queriesLang);

  const openAIDeployments = useMemo(() => {
    /*     if (azureOpenAIDeployments.length > 0) {
      return azureOpenAIDeployments
        .filter((item) => !item.deploymentName.includes("embedding"))
        .map((item) => {
          return { content: [item.deploymentName] };
        });
    } else {
      return [];
    } */
    return [{ content: ["gpt-4o"] }, { content: ["gpt-4o-mini"] }];
  }, [azureOpenAIDeployments]);

  const handleSettingChange = (key, value) => {
    dispatch(setSetting({ key, value }));
  };

  const handleUpdateSearchIndex = () => {
    api
      .post(`/update-search-index/${params.tile}`)
      .then(() => {
        setIsLoading(false);

        setIsIndexing(true);
      })
      .catch((err) => {
        toast.error("Unable to update search index");
        console.error(err);
      });
  };

  const handleCreateSearchIndex = () => {
    setIsCreatingIndex(true);
    api
      .post(`/create-search-index/${params.tile}`)
      .then(() => {
        checkAndSetSearchIndexStatus();
        setIsCreatingIndex(false);
      })
      .catch((err) => {
        toast.error("Unable to create search index");
        setIsCreatingIndex(false);
        console.error(err);
      });
  };
  const checkAndSetSearchIndexStatus = () => {
    // Check if index is created for this
    api
      .get(`/check-search-index-is-created/${params.tile}`)
      .then((res) => {
        setIsIndexCreated(res.data.isIndexCreated);
      })
      .catch((err) => {
        toast.error("Failed to check search index status");
        console.error(err);
      });
  };

  const pollSearchIndexerStatus = () => {
    const interval = setInterval(() => {
      api
        .get(`/search-indexer-status/${params.tile}`)
        .then((res) => {
          if (res.data.status === "success") {
            setIsIndexing(false);
            clearInterval(interval);
            toast.success(
              "Indexing complete, but it may still take a few seconds before the new documents are available in the index."
            );
          }
        })
        .catch((err) => {
          toast.error("Failed to get search indexer status");
          clearInterval(interval);
          setIsIndexing(false);
          console.error(err);
        });
    }, 3000); // Poll every 3 seconds
    return interval;
  };

  useEffect(() => {
    /*     api
      .get("/get-azure-openai-deployments")
      .then((res) => setAzureOpenAIDeployments(res.data))
      .catch(console.log); */
    setAzureOpenAIDeployments([
      { deploymentName: "gpt-4o", modelName: "gpt-4o" },
      { deploymentName: "gpt-4o-mini", modelName: "gpt-4o-mini" },
    ]);
    let interval;
    if (isIndexing) {
      // TODO: check status when loading page first time aswell
      interval = pollSearchIndexerStatus();
    }
    return () => {
      clearInterval(interval);
    };
  }, [isIndexing]);

  useEffect(() => {
    api
      .get("/tools")
      .then((res) => {
        console.log(res.data);
        setToolsArray(Object.values(res.data));
      })
      .catch(console.log);
  }, []);

  useEffect(
    () => (document.title = `${params.tile?.toUpperCase()}: Backend Settings`),
    [params.tile]
  );

  useEffect(() => {
    setIsLoading(true);
    api
      .get(`/appconfig/${params.tile}`)
      .then((res) => {
        dispatch(setSettings(res.data));
        // Check if the search index is created
        checkAndSetSearchIndexStatus();

        setIsLoading(false);
      })
      .catch((err) => {
        toast.error("Unable to load backend settings");
        console.error(err);
        setIsLoading(false);
      });

    return () => {
      dispatch(resetBackendSettings());
    };
  }, [params.tile, dispatch, lang]);

  useEffect(() => {
    if (mounted1.current) {
      if (settings.useSearch === "true" && settings.availableTools !== "") {
        alert("Using tools with RAG is currently not supported.");
        dispatch(setSetting({ key: "availableTools", value: "" }));
      }
    } else {
      mounted1.current = true;
    }
  }, [settings.useSearch]);

  useEffect(() => {
    if (mounted2.current) {
      if (settings.availableTools && settings.availableTools.length > 0) {
        console.log("aps");
        dispatch(setSetting({ key: "useSearch", value: "false" }));
      }
    } else {
      mounted2.current = true;
    }
  }, [settings.availableTools]);

  useEffect(() => {
    api
      .get("/blob-storage-url")
      .then((res) => setBlobStorageUrl(res.data.url))
      .catch((err) => {
        toast.error("Unable to load blob storage url");
        console.error(err);
      });
  }, []);

  console.log(settings.modelName);

  // let _ = `https://${azureOpenAIResourceName}.openai.azure.com/openai/deployments/${azureOpenAIEmbeddingName}/embeddings?api-version=${apiVersion}`

  return !isLoading ? (
    <PageWrapper>
      <ContentWrapper>
        <ColumsWrapper>
          <ColumnDiv>
            <TextDiv>
              <InputWrapper>
                <Label>{t("backendSettingsPage.welcomeMessageEN")}</Label>
                <Spacing12 />
                <StyledInput
                  type="text"
                  placeholder={t("common.ClickToAdd")}
                  value={settings.welcomeMessageEN}
                  onChange={(e) =>
                    handleSettingChange("welcomeMessageEN", e.target.value)
                  }
                />
              </InputWrapper>
              <InputWrapper>
                <Label>{t("backendSettingsPage.welcomeMessageNO")}</Label>
                <Spacing12 />
                <StyledInput
                  type="text"
                  placeholder={t("common.ClickToAdd")}
                  value={settings.welcomeMessageNO}
                  onChange={(e) =>
                    handleSettingChange("welcomeMessageNO", e.target.value)
                  }
                />
              </InputWrapper>
              <InputWrapper>
                <Label>{t("backendSettingsPage.systemPrompt")}</Label>
                <Spacing12 />

                <SettingsAccordion
                  title={t("backendSettingsPage.systemPrompt")}
                >
                  <StyledTextArea
                    placeholder={t("uiSettingsPage.description")}
                    value={settings.systemPrompt}
                    rows={4}
                    onChange={(e) =>
                      handleSettingChange("systemPrompt", e.target.value)
                    }
                  />
                </SettingsAccordion>
              </InputWrapper>
              <InputWrapper>
                <Label>{t("backendSettingsPage.selectTools")}</Label>
                <Spacing12 />
                <MultiSelectDropdownMenu
                  onChange={(name) => {
                    if (settings.availableTools) {
                      if (settings.availableTools.includes(name)) {
                        handleSettingChange(
                          "availableTools",
                          [...settings.availableTools.split(",")]
                            .filter((nameparam) => nameparam != name)
                            .join(",")
                        );
                      } else {
                        handleSettingChange(
                          "availableTools",
                          [...settings.availableTools.split(","), name].join(
                            ","
                          )
                        );
                      }
                    } else {
                      handleSettingChange("availableTools", name);
                    }
                  }}
                  data={toolsArray?.length > 0 ? toolsArray : []}
                  selectedItems={settings.availableTools?.split(",") || []}
                  setLinkStatus={() => {}}
                  useMinWidth={true}
                />
              </InputWrapper>
            </TextDiv>
          </ColumnDiv>
          <Divider />
          <ColumnDiv>
            <TextDiv>
              <InputWrapper>
                <Label>{t("backendSettingsPage.deploymentModel")}</Label>
                <Spacing12 />
                <DropdownMenu
                  onChange={(e) => {
                    handleSettingChange(
                      "deploymentName",
                      azureOpenAIDeployments.find(
                        (item) => item.deploymentName === e
                      ).deploymentName
                    );
                    handleSettingChange(
                      "modelName",
                      azureOpenAIDeployments.find(
                        (item) => item.deploymentName === e
                      ).modelName
                    );
                  }}
                  data={openAIDeployments}
                  selectedItem={{ content: [settings.deploymentName] }}
                  setLinkStatus={() => {}}
                  useMinWidth={true}
                />
              </InputWrapper>
              <InputWrapper>
                <Label>{t("backendSettingsPage.maxTokens")}</Label>
                <Spacing12 />
                <StyledInput
                  type="number"
                  placeholder={t("common.ClickToAdd")}
                  value={settings.maxTokens}
                  onChange={(e) =>
                    handleSettingChange("maxTokens", e.target.value)
                  }
                />
              </InputWrapper>
              <InputWrapper>
                <Label>{t("backendSettingsPage.temperature")}</Label>
                <Spacing12 />
                <StyledInput
                  type="number"
                  step="0.1"
                  placeholder={t("common.ClickToAdd")}
                  value={settings.temperature}
                  onChange={(e) =>
                    handleSettingChange("temperature", e.target.value)
                  }
                />
              </InputWrapper>
              <InputWrapper>
                <Spacing12 />
                <Spacing12 />
                <Spacing12 />
                <CheckboxWrapper>
                  <Switch
                    label_position="left"
                    label={
                      <Label color="white">
                        {t("backendSettingsPage.useSearch")}
                      </Label>
                    }
                    checked={settings.useSearch}
                    onChange={(val) => {
                      handleSettingChange(
                        "useSearch",
                        val.checked ? "true" : "false"
                      );
                    }}
                  />
                  <Switch
                    label_position="left"
                    label={
                      <Label color="white">
                        {t("backendSettingsPage.keepContext")}
                      </Label>
                    }
                    checked={settings.keepContext}
                    onChange={(val) => {
                      handleSettingChange(
                        "keepContext",
                        val.checked ? "true" : "false"
                      );
                    }}
                  />
                </CheckboxWrapper>
              </InputWrapper>
            </TextDiv>
          </ColumnDiv>
        </ColumsWrapper>

        {settings.useSearch === "true" && (
          <SettingsAccordion
            title={t("backendSettingsPage.rag")}
            subtitleList={[]}
            setParentIsOpen={() => handleSettingChange(settings.useSearch)}
            initialIsOpenState={false}
          >
            <ColumsWrapper>
              <ColumnDiv>
                <TextDiv>
                  <InputWrapper>
                    <Label>
                      {t("backendSettingsPage.azureCognitiveSearchQueryType")}
                    </Label>
                    <Spacing12 />
                    <DropdownMenu
                      onChange={(e) =>
                        handleSettingChange("azureCognitiveSearchQueryType", e)
                      }
                      data={[
                        { content: ["simple"] },
                        { content: ["semantic"] },
                        { content: ["vector"] },
                        { content: ["vectorSimpleHybrid"] },
                        { content: ["vectorSemanticHybrid"] },
                      ]}
                      selectedItem={{
                        content: [settings.azureCognitiveSearchQueryType],
                      }}
                      setLinkStatus={() => {}}
                      useMinWidth={true}
                    />
                  </InputWrapper>
                  <InputWrapper>
                    <Label>{t("backendSettingsPage.showSources")}</Label>
                    <Spacing12 />
                    <DropdownMenu
                      onChange={(e) => handleSettingChange("showSources", e)}
                      data={[{ content: ["true"] }, { content: ["false"] }]}
                      selectedItem={{ content: [settings.showSources] }}
                      setLinkStatus={() => {}}
                      useMinWidth={true}
                    />
                  </InputWrapper>
                  <InputWrapper>
                    <Label>{t("backendSettingsPage.maximumPageLength")}</Label>
                    <Spacing12 />
                    <StyledInput
                      type="number"
                      placeholder={t("common.ClickToAdd")}
                      value={settings.maximumPageLength}
                      onChange={(e) =>
                        handleSettingChange("maximumPageLength", e.target.value)
                      }
                    />
                  </InputWrapper>
                  <InputWrapper>
                    <Button
                      variant="secondary"
                      type="submit"
                      onClick={
                        isIndexCreated
                          ? handleUpdateSearchIndex
                          : handleCreateSearchIndex
                      }
                      disabled={isIndexing || isCreatingIndex}
                    >
                      {isIndexing || isCreatingIndex ? (
                        <LoaderIcon></LoaderIcon>
                      ) : isIndexCreated ? (
                        "Run Indexer"
                      ) : (
                        "Create Index"
                      )}
                    </Button>
                    <Spacing12 />
                    {/* TODO: set this url in config */}
                    {isIndexCreated && (
                      <>
                        {" "}
                        <LightLabel>
                          {t("backendSettingsPage.updateSearchIndex")}
                        </LightLabel>
                        <Link
                          href={`${blobStorageUrl}${params.tile}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Blob Storage
                        </Link>
                      </>
                    )}
                  </InputWrapper>
                </TextDiv>
              </ColumnDiv>
              <Divider />
              <ColumnDiv>
                <>
                  <TextDiv>
                    <InputWrapper>
                      <Label>{t("backendSettingsPage.topNDocuments")}</Label>
                      <Spacing12 />
                      <StyledInput
                        type="number"
                        placeholder={t("common.ClickToAdd")}
                        value={settings.topNDocuments}
                        onChange={(e) =>
                          handleSettingChange("topNDocuments", e.target.value)
                        }
                      />
                    </InputWrapper>
                    <InputWrapper>
                      <Label>{t("backendSettingsPage.strictness")}</Label>
                      <Spacing12 />
                      <StyledInput
                        type="number"
                        placeholder={t("common.ClickToAdd")}
                        value={settings.strictness}
                        onChange={(e) =>
                          handleSettingChange("strictness", e.target.value)
                        }
                      />
                    </InputWrapper>
                    <InputWrapper>
                      <Label>
                        {t("backendSettingsPage.pageOverlapLength")}
                      </Label>
                      <Spacing12 />
                      <StyledInput
                        type="number"
                        placeholder={t("common.ClickToAdd")}
                        value={settings.pageOverlapLength}
                        onChange={(e) =>
                          handleSettingChange(
                            "pageOverlapLength",
                            e.target.value
                          )
                        }
                      />
                    </InputWrapper>
                  </TextDiv>
                </>
              </ColumnDiv>
            </ColumsWrapper>
          </SettingsAccordion>
        )}
        <SaveButtonWrapper>
          <SaveButton style={{ width: "auto" }} disabled={false} />
        </SaveButtonWrapper>
      </ContentWrapper>
      {/*       <ButtonWrapper>
        <HistoryButton />
      </ButtonWrapper> */}
    </PageWrapper>
  ) : (
    <Loader />
  );
}

const CheckboxWrapper = styled.div`
  justify-content: space-evenly;
  align-items: center;
  back
  width: 100%;
  display: flex;
  flex-direction: row;
  padding: var(--spacing-small);
  background-color: ${(props) => props.theme.buttonBackgroundColor};
  border: 1px solid ${(props) => props.theme.color};
  border-radius: 0.5rem;
`;

const Spacing12 = styled.div`
  height: 12px;
`;
/* 
const StyledCheckbox = styled(Checkbox)`
  z-index: 0;
`;
 */
