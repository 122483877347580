import { Checkbox, HelpButton, Icon } from "@dnb/eufemia";
import { chevron_down } from "@dnb/eufemia/icons";
import { keyframes } from "@emotion/react";
import styled from "@emotion/styled";
import { useEffect, useRef, useState } from "react";

export default function MultiSelectDropdownMenu({
  onChange,
  data,
  selectedItems,
  setLinkStatus,
  useMinWidth = false,
}) {
  const [isOpen, setIsOpen] = useState(false);
  const wrapperRef = useRef(null);
  useEffect(() => {
    function handleOutsideClick(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setIsOpen(false);
        setLinkStatus(false);
      }
    }

    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  function handleItemClick(item) {
    onChange(item);
  }

  const Wrapper = useMinWidth ? MinWidthDropdownWrapper : DropdownWrapper;

  return (
    <Wrapper ref={wrapperRef}>
      <DropdownButton
        onClick={() => {
          setIsOpen(!isOpen);
          setLinkStatus(!isOpen);
        }}
      >
        <DropdownButtonText>
          {selectedItems.length > 0 ? (
            <>
              {selectedItems.map((item, index) => {
                return (
                  <>
                    <DropdownButtonTitle key={item}>
                      {item}
                      {index !== selectedItems.length - 1 ? "," : ""}
                    </DropdownButtonTitle>
                  </>
                );
              })}
            </>
          ) : (
            <DropdownButtonTitle>Select</DropdownButtonTitle>
          )}
        </DropdownButtonText>
        <Icon icon={chevron_down} />
      </DropdownButton>
      {isOpen && (
        <DropdownList>
          {data.map((item) => (
            <DropdownItem
              key={item.name}
              onClick={() => handleItemClick(item.name)}
            >
              <ItemTitle>{item.name}</ItemTitle>
              <StyledCheckbox
                onChange={() => handleItemClick(item.name)}
                checked={selectedItems.includes(item.name)}
                size="large"
                style={{ width: "100%", color: "white" }}
                suffix={
                  <HelpButton title={item.name}>{item.description}</HelpButton>
                }
              >
                {item.name}
              </StyledCheckbox>
            </DropdownItem>
          ))}
          {/* {data.map((item) =>
            item.content.length === 2 ? (
              <>
                <DropdownItem
                  key={item.content[1]}
                  onClick={() => handleItemClick(item)}
                >
                  <ItemTitle>{item}</ItemTitle>
                </DropdownItem>
              </>
            ) : (
              <DropdownItem
                key={item.content[0]}
                onClick={() => handleItemClick(item)}
              >
                <ItemTitle>{item}</ItemTitle>
              </DropdownItem>
            )
          )} */}
        </DropdownList>
      )}
    </Wrapper>
  );
}

const StyledCheckbox = styled(Checkbox)``;

const DropdownWrapper = styled.div`
  position: relative;
  width: 20rem;
  margin-top: 1rem;
`;

const MinWidthDropdownWrapper = styled.div`
  position: relative;
  min-width: 20rem;
  width: 100%;
`;

const DropdownButton = styled.button`
  width: 100%;
  border: 1px solid white;
  border-radius: 8px;
  padding: 10px;
  min-height: 3.625rem;

  background-color: #313131;
  color: white;

  text-overflow: ellipsis;

  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;

  text-align: left;

  &:hover {
    background-color: ${(props) => props.theme.hoverColor};
    color: ${(props) => props.theme.hoverTextColor};
    border: 1px solid ${(props) => props.theme.hoverBorderColor};
  }
`;

const DropdownButtonText = styled.div`
  width: 80%;
  display: flex;
  gap: 8px;
`;

const DropdownButtonTitle = styled.div`
  font-weight: 500;
  font-size: 18px;
`;

const appear = keyframes`
  0% { opacity: 0; transform: translateY(-20px); }
  100% { opacity: 1; transform: translateY(0); }
`;

const DropdownList = styled.div`
  position: absolute;
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 4px;
  z-index: 1;
  animation: ${appear} 0.2s ease-in-out forwards;
`;

const DropdownItem = styled.div`
  padding: 10px;
  cursor: pointer;
  width: 100%;
  min-height: 2.625rem;
  border: 1px solid white;
  border-radius: 8px;
  padding: 20px;
  background-color: #313131;
  color: white;
  justify-content: space-between;
  align-items: center;
  display: flex;
  flex-direction: row;

  &:hover {
    background-color: ${(props) => props.theme.hoverColor};
    color: ${(props) => props.theme.hoverTextColor};
    border: 1px solid ${(props) => props.theme.hoverBorderColor};
  }
`;

const ItemTitle = styled.div`
  font-weight: 500;
  font-size: 18px;
  color: white;
  width: 100%;
`;
