import styled from "@emotion/styled";

export const Divider = styled.div`
  height: 30rem;
  width: 1.5px;
  background-color: #313131;

  margin-left: 2rem;
  margin-right: 2rem;
`;

export const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  width: 75vw;
  max-width: 1200px;
  font-size: var(--font-size-basis);

  @media screen and (max-height: 1000px) {
    margin-top: var(--spacing-x-large);
  }
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

export const SaveButtonWrapper = styled.div`
  margin-top: 1rem;
`;

export const ColumsWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;

  margin-bottom: 0.625rem;
`;

export const ColumnsSpaceBetweenWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  
  gap: 3rem;

  margin-bottom: 0.625rem;
`;



export const ColumnDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  width: 100%;
`;

export const TextDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  gap: 1rem;

  width: 100%;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.625rem;
`;

export const CardWrapper = styled.div`
  display: flex;
  gap: 6rem;

  @media screen and (max-height: 700px) {
    gap: 2rem;
  }
`;

export const StyledTextArea = styled.textarea`
  /* Reset all default styles */
  appearance: none;
  border: none;
  margin: 0;
  padding: 16px;
  outline: none;
  resize: ${(props) => {props.resize || 'none'}};
  overflow-x: hidden;
  overflow-y: ${props => props.overflowY || 'hidden'};
  width: 100%;
  height: 100%;

  /* Original Figma Sketch style */
  /* background-color: transparent;
  color: ${(props) => props.theme.color}; */

  /* Modified style */
  background-color: ${(props) => props.theme.buttonBackgroundColor};
  border: 1px solid ${(props) => props.theme.color};
  color: #ffffff;

  border-radius: 0.5rem;

  :focus {
    border-radius: 0.2rem;
  }
`;

export const StyledInput = styled.input`
  background-color: ${(props) => props.theme.buttonBackgroundColor};
  border: 1px solid ${(props) => props.theme.color};
  color: #ffffff;
  border-radius: 0.5rem;
  ::placeholder {
    color: #ffffff;
  }
  opacity: ${(props) => props.opacity};
  min-width: 18.75rem;
  width: 100%;

  box-shadow: none;
  padding: var(--spacing-small);

  :focus {
    border-radius: 0.2rem;
  }

  /* Remove number input buttons */
  &[type="number"]::-webkit-inner-spin-button,
  &[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  :focus {
    outline: none;
  }
`;


