import { trash_medium } from "@dnb/eufemia/icons";
import Dialog from "./Dialog";
import { useTranslation } from "react-i18next";

export default function DeleteDialog({ handleRemove, closeDialog,type }) {

  const { t } = useTranslation();
   var message = type==="parent" ? t("dialogs.delete.parentTitle") : t("dialogs.delete.title");
   return (
    <Dialog
      title={message}
      icon={trash_medium}
      discardText={t("dialogs.delete.discardText")}
      acceptText={t("dialogs.delete.acceptText")}
      content={t("dialogs.delete.content")}
      onClose={closeDialog}
      onConfirm={() => {
        closeDialog();
        handleRemove()
      }}
      onExit={closeDialog}
    />
  );
}
