import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  parameters: [
    {
      key: "",
      value: "",
      display: true,
      link: false,
      description: "",
      additionalInfo: "",
      displayName: "",
      buttonText: "",
      tileTipsTips: "",
      tileTipsUser: "",
      tileTipsGenai: "",
      deletable: true,
    },
  ],
};

export const uisettingsSlice = createSlice({
  name: "uisettings",
  initialState: initialState,
  reducers: {
    setFilterProperty: (state, action) => {
      const { index, property, value } = action.payload;
      state.parameters[index][property] = value;
    },
    setFilter: (state, action) => {
      const { index, filter } = action.payload;
      state.parameters[index] = filter;
    },
    setParameters: (state, action) => {
      state.parameters = action.payload;
    },
    resetParameters: (state) => {
      state.parameters = initialState.parameters;
    },
  },
});

export const { setFilterProperty, setFilter, setParameters, resetParameters } =
  uisettingsSlice.actions;
export default uisettingsSlice.reducer;
