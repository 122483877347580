import { pin_medium } from "@dnb/eufemia/icons";
import { useDispatch } from "react-redux";
import { setChanges } from "../../features/changeSlice";
import { useNavigate } from "react-router-dom";
import Dialog from "./Dialog";
import useHandleSave from "../../hooks/useHandleSave";
import { useTranslation } from "react-i18next";
import styled from "@emotion/styled/macro";

export default function SaveDialog({
  open,
  setOpen,
  href,
  onSave,
  customSaveText,
  onDiscard,
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { t } = useTranslation();

  const handleSave = useHandleSave();

  return (
    <>
      {open && (
        <StyledDialog
          title={t("dialogs.save.title")}
          icon={pin_medium}
          discardText={t(`${customSaveText || "dialogs.save.discardText"}`)}
          acceptText={t("dialogs.save.acceptText")}
          content={t("dialogs.save.content")}
          onClose={() => {
            if (onSave) {
              onSave();
            } else {
              handleSave();
              setOpen(false);
              href ? navigate(href) : navigate(-1);
            }
          }}
          onConfirm={() => {
            if (onDiscard) {
              onDiscard();
            } else {
              dispatch(setChanges(false));
              setOpen(false);
              href ? navigate(href) : navigate(-1);
            }
          }}
          onExit={() => setOpen(false)}
        />
      )}
    </>
  );
}

const StyledDialog = styled(Dialog)`
  z-index: 214748364;
`;
