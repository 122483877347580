import { useDispatch, useSelector } from "react-redux";
import { toggleLang } from "../../features/langSlice";
import Switch from "./Switch";

export default function LangSwitch({ customHandleOnChange }) {
  const dispatch = useDispatch();
  const lang = useSelector((state) => state.lang.lang);

  function handleOnChange() {
    dispatch(toggleLang());
  }

  return (
    <Switch
      type="checkbox"
      checked={lang === "en"}
      onChange={customHandleOnChange || handleOnChange}
    />
  );
}
